<template>
  <div class="container" style="background: #F5FFEF" >
    <div class="bottom">
      <img src="../../static/bottomlogo.png" />
    </div>
    <a-layout   id="components-layout-demo-top-side">
      <a-layout-header class="header">
        <loginheader :activekey="4"></loginheader>
      </a-layout-header>
    </a-layout>
    <a-layout-content >
    <div class="produce_content">
      <div class="produce_item">
        <div class="leftitem">  <h3>企业概况</h3></div>
      <div class="rightitem">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;北京实益拓展科技有限责任公司成立于1989年2月，注册资金1260万元，系中关村高新技术企业。三十余年来实益企业以“海阔天空地想，脚踏实地地做，健康快乐地活”为企业文化，以“推出一代，储备一代，构思一代”为企业发展战略，以“看清楚、说明白、做到位”为工作原则，引领企业在激烈的市场大潮中艰苦创业，自强不息。</p>
      </div>
      </div>
      <div class="produce_item">
        <div class="leftitem">  <h3>企业转型</h3></div>
      <div class="rightitem">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;2019年，为积极响应国家《健康中国2030规划纲要》的号召，实益企业果断转型至健康产业，斥资打造“健康知识普及培训系统”细化落实《健康中国行动》对健康知识普及行动的工作要求。</p>
      </div>
      </div>
      <div class="produce_item">
        <div class="leftitem">  <h3>企业资质</h3></div>
      <div class="rightitem">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;企业具有广播电视局颁发的《广播电视节目制作经营许可证》，具备音、视频节目制作的资质。</p>
      </div>
      </div>
      <div class="produce_item">
        <div class="leftitem">  <h3>全国招商</h3></div>
      <div class="rightitem">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;通过三年的潜心打造，于2021年推出《中医药文化于劳动技能系统教育课程》，并以北京为原点，面向全国全面招商。</p>
      </div>
      </div>
    </div>
    </a-layout-content>

    <a-layout-footer style="padding: 0;position: absolute;bottom: 0;width: 100%;">
      <loginfooter></loginfooter>
    </a-layout-footer>
  </div>

</template>

<script>
import loginheader from "../home/components/loginheader";
import loginfooter from "../home/components/loginfooter";
export default {
  name: "companyproduce",
  components:{
    loginheader:loginheader,
    loginfooter:loginfooter
  },
  data(){
    return {

        // 视频播放
        playerOptions : {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "" //url地址
          }],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth, //播放器宽度
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        }


    }
  },
  methods:{

  }
}
</script>
<style>
@media (min-width: 1024px){
  body,html{font-size: 18px!important;}
}
@media (min-width: 1100px) {
  body,html{font-size: 20px!important;}
}
@media (min-width: 1280px) {
  body,html{font-size: 22px!important;}
}
@media (min-width: 1366px) {
  body,html{font-size: 24px!important;}
}
@media (min-width: 1440px) {
  body,html{font-size: 25px!important;}
}
@media (min-width: 1680px) {
  body,html{font-size: 28px!important;}
}
@media (min-width: 1920px) {
  body,html{font-size: 33px!important;}
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header{
  height: 2.76rem;
  background: linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);
  box-sizing: border-box;
}
.produce_content{
display: flex;
background: #FFFFFF;
  flex-direction: column;
  width: 1403px;
  margin:1rem auto;
  .produce_item{
    display: flex;
    margin-bottom:50px;
    flex-direction: row;

   .leftitem{
    
    h3{
      background: #85C25F;
      font-size:32px;
      width: 185px;
      padding: 5px 0 0 25px;
    height:87px;
    line-height: 66px;
      color: #FFFFFF;
    }
   }
   .rightitem{
    margin-left: 35px;
    p{
      color:#444444;
      font-size:24px;
      line-height:40px;
    }
   }
  }
}
.bottom{
  padding: 0;
  z-index: 999;
  position: absolute;
  width: 10rem;

  bottom:0;
  right: 0;
  width:11.8rem;
  img{
    width: 100%;
  }

}
</style>